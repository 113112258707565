.layout-config-button {
  position: fixed;
  width: 4rem;
  height: 4rem;
  background: var(--primary-color);
  color: var(--primary-color-text);
  top: 50%;
  right: 0;
  margin-top: -2rem;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition: background-color var(--transition-duration);
  overflow: hidden;
  cursor: pointer;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -0.25rem 0 1rem rgba(0, 0, 0, 0.15);

  i {
    font-size: 2.5rem;
    line-height: inherit;
    transform: rotate(0deg);
    transition: transform 1s;
  }

  &:hover {
    background-color: var(--primary-400);
  }
}
