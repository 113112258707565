$base-black: #000000 !default;
$base-white: #ffffff !default;

$primary-50: #f2f7f4 !default;
$primary-100: #fdf7f9 !default;
$primary-200: #feebf2 !default;
$primary-300: #df9fc5 !default;
$primary-400: #e54ea6 !default;
$primary-500: #e61f93 !default;
$primary-600: #a7136a !default;
$primary-700: #6d0943 !default;
$primary-800: #380220 !default;
$primary-900: #380220 !default;
$primary-950: #101e19 !default;

$grey-25: #f9f8f8 !default;
$grey-50: #f3f3f3 !default;
$grey-100: #e2dfdf !default;
$grey-200: #c6c3c2 !default;
$grey-300: #a69e9e !default;
$grey-400: #8c8384 !default;
$grey-500: #776e6f !default;
$grey-600: #6b6365 !default;
$grey-700: #575152 !default;
$grey-800: #4c4748 !default;
$grey-900: #434042 !default;
$grey-950: #252324 !default;
$grey-bg: #fafafa;

$green-50: #dff5e5 !default;
$green-200: #8fc89f !default;
$green-500: #0f9045 !default;
$green-600: #1a6a32 !default;

$yellow-50: #fff7de !default;
$yellow-200: #ffe6b5 !default;
$yellow-500: #ffc247 !default;
$yellow-600: #d29f39 !default;

$red-50: #fbebea !default;
$red-200: #f1adaa !default;
$red-500: #db332a !default;
$red-600: #af2822 !default;

$success: #0f9045 !default;
$error: #db332a !default;
$warn: #ffc247 !default;
$info: #3a51d6 !default;

$dark-blue: #0c102b !default;
$home-background: #eff3f8;
