@import '~swiper/swiper-bundle.css';
@import 'libs/tfi-shared-utils/src/lib/styles/colors';

// CSS RESET
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

.custom-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-track {
  background: $grey-100;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3);
}

/* PrimeNG */
$gutter: 1rem; //for primeflex grid system
@import 'assets/layout/styles/layout/layout.scss';
@import '../../../node_modules/primeng/resources/primeng.min.css';

/* Toastr*/
@import '../../../node_modules/ngx-toastr/toastr';

@import '~@angular/material/prebuilt-themes/indigo-pink.css';
